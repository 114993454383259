import React from "react";
import { getRandomKey } from "../../../helper/calculation";
export const PYTableData = (props) => {
  const {
    project_index,
    soccie,
    pr_index,
    SoccieProject,
    RestatedBalanceCalPy,
    ComprehensiveCalPy,
    TotalCalPy,
    showTotal,
    TotalRestatedBalanceCalPy,
    TotalRowCalPy,
    TotalComprehensiveCalPy,
    AcquisitionCalPy,
    TotalAcquisitionCalPy,
    TotalOfTotalPy,
    BalAtEndOfPrevReportPy,
    showAdjustment,
  } = props;
  const specialRow = 6; // Replace this with the index of your special row
  return (
    <React.Fragment>
      {
        pr_index === 2 ? (
          <>
            <tr key={getRandomKey()}  >
              {Object.keys(soccie).map((keyname, soccie_index) => (
                <React.Fragment>
                  {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                    ""
                  ) : keyname === "row_header" ? (
                    <td className="font-weight-bold text-black text-left">
                      {soccie[keyname]}
                    </td>
                  ) : (
                    <td className="font-weight-bold" style={{ backgroundColor: "rgb(200, 255, 219)" }}>
                      {RestatedBalanceCalPy(
                        SoccieProject,
                        pr_index,
                        keyname
                      ).toLocaleString("en-IN")}
                    </td>
                  )}
                </React.Fragment>
              ))}
              <React.Fragment key={getRandomKey()}>
                <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>{0}</td>
              </React.Fragment>
              {showTotal ? (
                <td
                  style={{
                    fontWeight: "600",
                    backgroundColor: "rgb(200, 255, 219)",
                  }}
                >
                  {TotalRestatedBalanceCalPy(pr_index).toLocaleString("en-IN")}
                </td>
              ) : (
                ""
              )}
              <td>{ }</td>
            </tr>
          </>
        ) :
          pr_index === 5 ? (
            <>
              <tr key={getRandomKey()}>
                {soccie &&
                  Object.keys(soccie).map((keyname, soccie_index) => (
                    <>
                      <React.Fragment>
                        {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                          ""
                        ) : keyname === "row_header" ? (
                          <>
                            <td className="font-weight-bold text-black text-left">
                              {soccie[keyname]}
                            </td>
                          </>
                        ) : (
                          <>
                            <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>
                              {ComprehensiveCalPy(
                                SoccieProject,
                                pr_index,
                                keyname
                              ).toLocaleString("en-IN")}
                            </td>
                          </>
                        )}
                      </React.Fragment>
                    </>
                  ))}
                <React.Fragment key={getRandomKey()}>
                  <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>{0}</td>
                </React.Fragment>
                {showTotal ? (
                  <td
                    style={{
                      fontWeight: "600",
                      backgroundColor: "rgb(200, 255, 219)",
                    }}
                  >
                    {TotalComprehensiveCalPy(pr_index).toLocaleString("en-IN")}
                  </td>
                ) : (
                  ""
                )}
                <td>{ }</td>
              </tr>
              <tr key={getRandomKey()}>
                {soccie &&
                  Object.keys(soccie).map((keyname, soccie_index) => (
                    <>
                      <React.Fragment>
                        {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                          ""
                        ) : keyname === "row_header" ? (
                          <>
                            <td style={{ color: "black", fontWeight: "bold", textAlign: "left" }}>
                              {"On account of acquisition of subsidiary"}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              {AcquisitionCalPy
                                ? AcquisitionCalPy(
                                  project_index,
                                  keyname,
                                  showAdjustment
                                ).toLocaleString("en-IN")
                                : 0}
                            </td>
                          </>
                        )}
                      </React.Fragment>
                    </>
                  ))}
                <React.Fragment key={getRandomKey()}>
                  <td>{"0.00"}</td>
                </React.Fragment>
                {showTotal ? (
                  <td>{TotalAcquisitionCalPy().toLocaleString("en-IN")}</td>
                ) : (
                  ""
                )}
                <td>{ }</td>
              </tr>
            </>
          ) :
            10 === pr_index ? (
              <>
                <tr key={getRandomKey()}>
                  <React.Fragment>
                    {soccie &&
                      Object.keys(soccie).map((keyname, soccie_index) => (
                        <React.Fragment>
                          {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                            ""
                          ) :
                            (
                              <>
                                <td
                                  style={isNaN(Number(soccie[keyname])) ? { textAlign: "start" } : { textAlign: "center" }}
                                  className={`${[2, 5].includes(pr_index) ? "font-weight-bold" : ""}`}
                                >
                                  {!isNaN(Number(soccie[keyname]))
                                    ? Number(soccie[keyname]).toLocaleString("en-IN")
                                    : soccie[keyname]}
                                </td>
                              </>
                            )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                  {pr_index !== 9 ? (
                    <React.Fragment key={getRandomKey()}>
                      <td>{0.0}</td>
                      {showTotal ? (
                        <td>{TotalRowCalPy(pr_index).toLocaleString("en-IN")}</td>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </tr>
                <tr key={getRandomKey()}>
                  {soccie &&
                    Object.keys(soccie).map((keyname, soccie_index) => (
                      <React.Fragment>
                        {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                          ""
                        ) : keyname === "row_header" ? (
                          <td style={{ color: "black", fontWeight: "bold", textAlign: "left" }}>
                            {"Total"}
                          </td>
                        ) : (
                          <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>
                            {TotalCalPy(
                              SoccieProject,
                              pr_index,
                              keyname,
                              project_index,
                              showAdjustment
                            ).toLocaleString("en-IN")}
                          </td>
                        )}
                      </React.Fragment>
                    ))}
                  <React.Fragment key={getRandomKey()}>
                    <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>{0}</td>
                  </React.Fragment>
                  {showTotal ? (
                    <td
                      style={{
                        fontWeight: "600",
                        backgroundColor: "rgb(200, 255, 219)",
                      }}
                    >
                      {TotalOfTotalPy(pr_index).toLocaleString("en-IN")}
                    </td>
                  ) : (
                    ""
                  )}
                  <td>{ }</td>
                </tr>
                <tr key={getRandomKey()}>
                  {soccie &&
                    Object.keys(soccie).map((keyname, soccie_index) => (
                      <React.Fragment>
                        {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                          ""
                        ) : keyname === "row_header" ? (
                          <td style={{ color: "black", fontWeight: "bold", textAlign: "left" }}>
                            {"Balance at the end of the previous reporting period"}
                          </td>
                        ) : (
                          <td style={{ backgroundColor: "rgb(254, 255, 187)" }}>
                            {showTotal
                              ? (
                                Number(
                                  RestatedBalanceCalPy(SoccieProject, 2, keyname)
                                ) +
                                Number(
                                  ComprehensiveCalPy(SoccieProject, 4, keyname)
                                ) +
                                Number(
                                  TotalCalPy(
                                    SoccieProject,
                                    9,
                                    keyname,
                                    project_index,
                                    showAdjustment
                                  )
                                )
                              ).toLocaleString("en-IN")
                              : BalAtEndOfPrevReportPy(
                                SoccieProject,
                                keyname,
                                project_index,
                                showAdjustment
                              ).toLocaleString("en-IN")}
                          </td>
                        )}
                      </React.Fragment>
                    ))}
                  <React.Fragment key={getRandomKey()}>
                    <td style={{ backgroundColor: "rgb(254, 255, 187)" }}>{0}</td>
                  </React.Fragment>
                  {showTotal ? (
                    <td
                      style={{
                        fontWeight: "600",
                        backgroundColor: "rgb(254, 255, 187)",
                      }}
                    >
                      {(
                        Number(TotalRestatedBalanceCalPy(2)) +
                        Number(TotalComprehensiveCalPy(4)) +
                        Number(TotalOfTotalPy(9))
                      ).toLocaleString("en-IN")}
                    </td>
                  ) : (
                    ""
                  )}
                  <td>{ }</td>
                </tr>
              </>
            ) : (
              <tr key={getRandomKey()}>
                <React.Fragment>
                  {soccie &&
                    Object.keys(soccie).map((keyname, soccie_index) => (
                      <React.Fragment>
                        {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                          ""
                        ) :
                          //  pr_index === 9 ? (
                          //   ""
                          // ) : 
                          (
                            <>
                              <td
                                style={isNaN(Number(soccie[keyname])) ? { textAlign: "start" } : { textAlign: "center" }}
                                className={`${[2, 5].includes(pr_index) ? "font-weight-bold" : ""}`}
                              >
                                {!isNaN(Number(soccie[keyname]))
                                  ? Number(soccie[keyname]).toLocaleString("en-IN")
                                  : soccie[keyname]}
                              </td>
                            </>
                          )}
                      </React.Fragment>
                    ))}
                </React.Fragment>
                {pr_index !== 9 ? (
                  <React.Fragment key={getRandomKey()}>
                    <td>{0.0}</td>
                    {showTotal ? (
                      <td>{TotalRowCalPy(pr_index).toLocaleString("en-IN")}</td>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </tr>
            )}
    </React.Fragment>
  );
};

export const CYTableData = (props) => {
  const {
    project_index,
    soccie,
    cr_index,
    SoccieProject,
    RestatedBalanceCalCy,
    ComprehensiveCalCy,
    TotalCalCy,
    showTotal,
    TotalRestatedBalanceCalCy,
    TotalRowCalCy,
    TotalComprehensiveCalCy,
    AcquisitionCalCy,
    TotalAcquisitionCalCy,
    TotalOfTotalCy,
    showAdjustment,
  } = props;

  return (
    <React.Fragment key={getRandomKey()}>
      {
        cr_index === 2 ? (
          <>
            <tr key={getRandomKey()}>
              {Object.keys(soccie).map((keyname, soccie_index) => (
                <React.Fragment key={getRandomKey()}>
                  {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                    ""
                  ) : keyname === "row_header" ? (
                    <td style={{ color: "black", fontWeight: "bold" }}>
                      {soccie[keyname]}
                    </td>
                  ) : (
                    <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>
                      {RestatedBalanceCalCy(
                        SoccieProject,
                        cr_index,
                        keyname
                      ).toLocaleString("en-IN")}
                    </td>
                  )}
                </React.Fragment>
              ))}
              <React.Fragment key={getRandomKey()}>
                <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>{0}</td>
              </React.Fragment>
              {showTotal ? (
                <td
                  style={{
                    fontWeight: "600",
                    backgroundColor: "rgb(200, 255, 219)",
                  }}
                >
                  {TotalRestatedBalanceCalCy(cr_index).toLocaleString("en-IN")}
                </td>
              ) : (
                ""
              )}
              <td>{ }</td>
            </tr>
          </>
        ) :
          cr_index === 5 ? (
            <>
              <tr key={getRandomKey()}>
                {Object.keys(soccie).map((keyname, soccie_index) => (
                  <React.Fragment key={getRandomKey()}>
                    {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                      ""
                    ) : keyname === "row_header" ? (
                      <td style={{ color: "black", fontWeight: "bold", textAlign: "left" }}>
                        {"Total Comprehensive Income for the previous year"}
                      </td>
                    ) : (
                      <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>
                        {ComprehensiveCalCy(
                          SoccieProject,
                          cr_index,
                          keyname
                        ).toLocaleString("en-IN")}
                      </td>
                    )}
                  </React.Fragment>
                ))}
                <React.Fragment key={getRandomKey()}>
                  <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>
                    {"0.00"}
                  </td>
                </React.Fragment>
                {showTotal ? (
                  <td
                    style={{
                      fontWeight: "600",
                      backgroundColor: "rgb(200, 255, 219)",
                    }}
                  >
                    {TotalComprehensiveCalCy(cr_index).toLocaleString("en-IN")}
                  </td>
                ) : (
                  ""
                )}
                <td>{ }</td>
              </tr>

              <tr key={getRandomKey()}>
                {Object.keys(soccie).map((keyname, soccie_index) => (
                  <>
                    <React.Fragment>
                      {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                        ""
                      ) : keyname === "row_header" ? (
                        <>
                          <td style={{ color: "black", fontWeight: "bold", textAlign: "left" }}>
                            {"On account of acquisition of subsidiary"}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {AcquisitionCalCy
                              ? AcquisitionCalCy(
                                project_index,
                                keyname,
                                showAdjustment
                              ).toLocaleString("en-IN")
                              : 0}
                          </td>
                        </>
                      )}
                    </React.Fragment>
                  </>
                ))}
                <React.Fragment key={getRandomKey()}>
                  <td>{"0.00"}</td>
                </React.Fragment>
                {showTotal ? (
                  <td>{TotalAcquisitionCalCy().toLocaleString("en-IN")}</td>
                ) : (
                  ""
                )}
                <td>{ }</td>
              </tr>
            </>
          ) : cr_index === 10 ? (
            <>
              <tr key={getRandomKey()}>
                {Object.keys(soccie).map((keyname, soccie_index) => (
                  <React.Fragment key={getRandomKey()}>
                    {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                      ""
                    ) : (
                      <td style={soccie[keyname] ? { textAlign: "left" } : {}} >
                        {!isNaN(Number(soccie[keyname]))
                          ? Number(soccie[keyname]).toLocaleString("en-IN")
                          : soccie[keyname]}
                      </td>
                    )}
                  </React.Fragment>
                ))}
                <React.Fragment key={getRandomKey()}>
                  <td>{"0.00"}</td>
                </React.Fragment>
                {showTotal ? (
                  <td>{TotalRowCalCy(cr_index).toLocaleString("en-IN")}</td>
                ) : (
                  ""
                )}
                <td>{ }</td>
              </tr>
              <tr key={getRandomKey()}>
                {Object.keys(soccie).map((keyname, soccie_index) => (
                  <React.Fragment>
                    {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                      ""
                    ) : keyname === "row_header" ? (
                      <td style={{ color: "black", fontWeight: "bold", textAlign: "left" }}>
                        {"Total"}
                      </td>
                    ) : (
                      <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>
                        {TotalCalCy(
                          SoccieProject,
                          cr_index,
                          keyname,
                          project_index,
                          showAdjustment
                        ).toLocaleString("en-IN")}
                      </td>
                    )}
                  </React.Fragment>
                ))}
                <React.Fragment key={getRandomKey()}>
                  <td style={{ backgroundColor: "rgb(200, 255, 219)" }}>
                    {"0.00"}
                  </td>
                </React.Fragment>
                {showTotal ? (
                  <td
                    style={{
                      fontWeight: "600",
                      backgroundColor: "rgb(200, 255, 219)",
                    }}
                  >
                    {TotalOfTotalCy(cr_index).toLocaleString("en-IN")}
                  </td>
                ) : (
                  ""
                )}
                <td>{ }</td>
              </tr>
              <tr key={getRandomKey()}>
                {Object.keys(soccie).map((keyname, soccie_index) => (
                  <React.Fragment key={getRandomKey()}>
                    {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                      ""
                    ) : keyname === "row_header" ? (
                      <td style={{ color: "black", fontWeight: "bold", textAlign: "left" }}>
                        {"Balance at the end of the previous reporting period"}
                      </td>
                    ) : (
                      <td style={{ backgroundColor: "rgb(254, 255, 187)" }}>
                        {(
                          Number(RestatedBalanceCalCy(SoccieProject, 2, keyname)) +
                          Number(ComprehensiveCalCy(SoccieProject, 4, keyname)) +
                          Number(
                            TotalCalCy(
                              SoccieProject,
                              9,
                              keyname,
                              project_index,
                              showAdjustment
                            )
                          )
                        ).toLocaleString("en-IN")}
                      </td>
                    )}
                  </React.Fragment>
                ))}
                <React.Fragment key={getRandomKey()}>
                  <td style={{ backgroundColor: "rgb(254, 255, 187)" }}>{0}</td>
                </React.Fragment>
                {showTotal ? (
                  <td
                    style={{
                      fontWeight: "600",
                      backgroundColor: "rgb(254, 255, 187)",

                    }}
                  >
                    {(
                      Number(TotalRestatedBalanceCalCy(2)) +
                      Number(TotalComprehensiveCalCy(4)) +
                      Number(TotalOfTotalCy(8))
                    ).toLocaleString("en-IN")}
                  </td>
                ) : (
                  ""
                )}
                <td>{ }</td>
              </tr>
            </>
          ) : (
            <tr key={getRandomKey()}>
              <React.Fragment key={getRandomKey()}>
                {Object.keys(soccie).map((keyname, soccie_index) => (
                  <React.Fragment key={getRandomKey()}>
                    {['sub_grp', 'isHeading', 'refData'].includes(keyname) ? (
                      ""
                    )
                      //  : cr_index === 8 ? (
                      //   ""
                      // ) 
                      : (
                        <td style={isNaN(Number(soccie[keyname])) ? { textAlign: "start" } : {}} >
                          {!isNaN(Number(soccie[keyname]))
                            ? Number(soccie[keyname]).toLocaleString("en-IN")
                            : soccie[keyname]}
                        </td>
                      )}
                  </React.Fragment>
                ))}
              </React.Fragment>
              {cr_index !== 8 ? (
                <React.Fragment key={getRandomKey()}>
                  <td>{0.0}</td>
                  {showTotal ? (
                    <td>{TotalRowCalCy(cr_index).toLocaleString("en-IN")}</td>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                ""
              )}
            </tr>
          )}
    </React.Fragment>
  );
};

export const Opening = (props) => {
  return <>Welcome to Opening</>;
};
